import React from "react";
import Header from "../header/Header";
import "./Main.css";
import Navbar from "../navbar/Navbar";
import ContainerMenuList from "../../components/containerMenuList/ContainerMenuList";
import FooterMenuList from "../../components/footerMenuList/FooterMenuList";

function Main(props) {
  return (
    <main className="h-100 p-0 d-flex flex-column align-items-center">
      <Header data={props.data} />
      <Navbar data={props.data} />
      {props.data.map((item, index) => (
        <ContainerMenuList data={item} key={index} />
      ))}
      <FooterMenuList />
    </main>
  );
}

export default Main;
