import React from 'react'
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock } from 'react-icons/fa';
import "./footerMenuList.css"

function FooterMenuList() {
  return (
    <article id="contact" className="row w-100 ps-3 mb-3 d-flex flex-column align-items-center">
      <section className="col-10 m-auto d-flex flex-column align-items-center">
        <h2 className="p-5 my-5 colorBorder fs40 fw-bold customLetterSpacing">Nous Retrouver</h2>
      </section>
      <section className="col-10 mx-auto d-xl-flex justify-content-around row">
        <p className="col-8 col-xl-3 mx-auto mx-xl-0 d-flex align-items-center justify-content-between">
          <FaMapMarkerAlt size={30} />
          <a href="https://www.google.com/maps/place/L'artisan+Pizzaiolo/@49.1167593,6.1820928,17z/data=!3m1!4b1!4m6!3m5!1s0x4794dc1e52f94097:0x26d9862e25bcefb2!8m2!3d49.1167558!4d6.1846731!16s%2Fg%2F1v3hyxx6?entry=ttu" target="_blank" className="w-75 ms-xl-3 fs-6 text-lg-center">18b Boulevard Maginot METZ 57000, France.</a>
        </p>
        <p className="col-8 col-xl-3 mx-auto mx-xl-0 d-flex align-items-center justify-content-between">
          <FaPhone size={30} />
          <a href="tel:0387170531" target="_blank" className="w-75 fs-6 text-lg-center">
            03 87 17 05 31
          </a>
        </p>
        {/* <p className="col-lg-6 col-xl-3 d-flex align-items-center justify-content-between">
          <FaEnvelope size={30} />
          <a href="mailto:contact@lartisan-pizzaiolo.fr" target="_blank" className="w-75 fs-6 text-lg-center">
            contact@lartisan-pizzaiolo.fr
          </a>
        </p> */}
        <p id='formeHoraire' className="mx-auto col-8 mx-xl-0 d-flex align-items-center justify-content-between">
          <FaClock size={30} />
          <a href="https://www.google.com/maps/place/18bis+Bd+Andr%C3%A9+Maginot,+57000+Metz/@49.1167291,6.1846627,17z/data=!3m1!4b1!4m6!3m5!1s0x4794dc1e525d9f5d:0x8d6121976a0c39a4!8m2!3d49.1167291!4d6.1846627!16s%2Fg%2F11fmz8v5vn?entry=ttu" target="_blank" className="w-75 ms-xl-3 fs-6 text-lg-center">
            <span id='textNowarp' className='d-block'>
             Mardi - Samedi : 11h30 - 14h / 18h30 - 22h
            </span>
           <span className='w-100 d-block'>
            Lundi - Dimanche : Fermé
            </span>
          </a>
        </p>
      </section>
    </article>
  )
}

export default FooterMenuList