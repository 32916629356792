import React from 'react'
import { FaAsterisk } from "react-icons/fa";
import './headerMenuList.css';

function HeaderMenuList(props) {

    const ifContainerIsPizza = props.id === "pizza";
    const ifContainerIsFormule = props.id === "formule";
    const ifContainerIsEntree = props.id === "entree";
    const ifContainerIsSalade = props.id === "salade";
  return (
    <section className="col-10 m-auto d-flex flex-column justify-content-around align-items-center">
        <h2 className={`fs40 fw-bold p-3 my-5 text-center customLetterSpacing ${ifContainerIsPizza ? '' : 'mb-0 pb-0 colorBorder'}`}>
            {props.title}
        </h2>
      {ifContainerIsPizza ? (
        <>
          <p className="colortext">
            30cm - Toutes nos pizzas sont garnies de mozzarella sur une base de
            sauce tomate ou de crème (nature, curry ou aigre douce)
          </p>
          <p
            id="split"
            className="w-25 d-flex justify-content-center align-items-center colorItems"
          >
            <span className="border-top w-50 colorItems"></span>
            <FaAsterisk className="mx-3 colorItems" size={25} />
            <span className="border-top w-50 colorItems"></span>
          </p>
          <article className="w-100 d-flex justify-content-between align-items-center flex-nowrap row">
            <p className="col-2 text-md-nowrap flex-shrink-0 sizeTextNoWrap">
              Salade d'accompagnement pour tous nos plats
            </p>
            <span className="colorItems col-7 flex-fill overflow-hidden">
              {props.dots}
            </span>
            <p className="col-2 flex-shrink-0 widthPrice">4,50€</p>
          </article>
        </>
      ) : null}
      {ifContainerIsFormule ? (
        <>
           <p className="col-10 colortext listIngredients text-center p-5 my-5 mt-0 pt-0">
          Uniquement à livraison
        </p>
        </>
      ) : null}
      {ifContainerIsEntree || ifContainerIsSalade ? (
        <>
           <p className="col-10 colortext listIngredients text-center p-5 my-5 mt-0 pt-0">
          Uniquement à livraison
        </p>
        </>
      ) : null}
    </section>
  )
}

export default HeaderMenuList