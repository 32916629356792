import React, { useEffect } from 'react';
import Aside from '../partials/aside/Aside';
import Main from '../partials/main/Main';
import listeMenu from '../assets/data/ListMenu.json';
import './Home.css';


function useGriserLiensAncrage() {
  useEffect(() => {
    // Récupérer tous les liens d'ancrage de la page
    const liensAncrage = document.querySelectorAll('a[href^="#"]');
    
    // Stocker la dernière ancre visible
    let derniereAncreVisible = null;
    
    // Créer une instance de l'Intersection Observer
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const sectionId = entry.target.getAttribute('id');
        const lienAncrage = document.querySelector(`a[href="#${sectionId}"]`);
        

        if (lienAncrage) {
          // Vérifier si le lien d'ancrage existe
          if (entry.isIntersecting) {
            // Supprimer la classe "grayed" de l'ancienne dernière ancre visible
            if (derniereAncreVisible) {
              derniereAncreVisible.classList.remove('grayed');
            }
            
            lienAncrage.classList.add('grayed'); // Ajouter la classe "grayed" au lien d'ancrage
            derniereAncreVisible = lienAncrage; // Mettre à jour la dernière ancre visible

          } else if (derniereAncreVisible === lienAncrage) {
            lienAncrage.classList.remove('grayed'); // Supprimer la classe "grayed" du lien d'ancrage
            derniereAncreVisible = null; // Réinitialiser la dernière ancre visible
          }
        }
      });
    });
    
    // Observer chaque section de la page
    const sections = document.querySelectorAll('section');
    sections.forEach(section => {
      observer.observe(section);
    });
    
    // Vérifier si le lien d'ancrage correspond à une section présente sur la page
    const handleAnchorClick = e => {
      e.preventDefault(); // Empêcher le comportement de redirection par défaut
      const sectionCible = document.querySelector(e.target.getAttribute('href'));
      if (sectionCible) {
        sectionCible.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    
    // Parcourir tous les liens d'ancrage
    liensAncrage.forEach(lien => {
      // Ajouter un écouteur d'événement au clic sur chaque lien d'ancrage
      lien.addEventListener('click', handleAnchorClick);
      const sectionCible = document.querySelector(lien.getAttribute('href'));
      if (sectionCible) {
        observer.observe(sectionCible); // Observer la section cible pour mettre à jour la classe "grayed" du lien
      }
    });
    
    // Cleanup function
    return () => {
      liensAncrage.forEach(lien => {
        lien.removeEventListener('click', handleAnchorClick);
      });
    };
  },);
}

function Home() {

  useGriserLiensAncrage();
  return (
    <div className="vh-100 vw-100 m-0 p-0 row">
      <Aside data={listeMenu}/>
      <Main data={listeMenu}/>
    </div>
  )
}

export default Home;