import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import "./Aside.css";
import  Logo from "../../assets/images/Logo-artisan-pizzaiolo.png";

function Aside(props) {
  return (
    <aside className="h-100 p-0 d-none d-xl-block">
      <div className="w-100 h-25 d-flex justify-content-center align-items-center">
      
        <img
          id="logo"
          className="d-flex justify-content-center"
          src={Logo}
          alt="Logo artisan pizzaiolo"
          />
          
      </div>

      <div className="h-50 d-flex justify-content-center">
        <ul className="d-flex flex-column justify-content-around align-items-center w-75 list-unstyled border-top border-bottom">
          {props.data.map((item, index) => (
            <li key={index}>
              <a className="text-nowrap" href={item.href}>
                {item.label}
              </a>
            </li>
          ))}
          
        </ul>
      </div>
      <div className="h-25 d-flex flex-column justify-content-around align-items-center">
        <p>
          <a
            id="numero"
            href="tel:0387170531"
            className="fw-bold scale-on-hover text-decoration-none"
          >
            03 87 17 05 31
          </a>
        </p>
        <p className="w-50 d-flex justify-content-around">
          <a
            href="https://www.facebook.com/lartisan.pizzaiolo"
            target="_blank"
            rel="noreferrer"
            alt="lien vers le facebook de l'artisan pizzaïolo"
          >
            <FaFacebook className="scale-on-hover iconeSize" />
          </a>
          <a
            href="https://www.instagram.com/lartisanpizzaiolometz/"
            target="_blank"
            rel="noreferrer"
            alt="lien vers le instagram de l'artisan pizzaïolo"
          >
            <FaInstagram className="scale-on-hover iconeSize" />
          </a>
        </p>
      </div>
    </aside>
  );
}

export default Aside;
