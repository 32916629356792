import React from 'react'
import './navbar.css';


function Navbar(props) {
  return (
    <div className="container-fluid p-0 sticky-top d-none d-md-block d-xl-none" id="nav">
      <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
        <div className="container-fluid">
          <ul className="navbar-nav w-100 d-flex flex-row justify-content-around">
            {props.data.map((item, index) => (
              <li key={index}  className="nav-item">
                <a className="nav-link text-nowrap" href={item.href}>{item.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar