import React from "react";
import "./menuList.css";
import ImageDesserts from "../../assets/images/desserts.jpg";
import ImageDessertsBis from "../../assets/images/dessertsBis.png";
import ImageDessertsTer from "../../assets/images/dessertsTer.png";
import ImageBoisson from "../../assets/images/boisson.jpg";
import ImagePizza from "../../assets/images/pizzaBis.jpg";
import ImageCannette from "../../assets/images/canettes.png";
import ImageSaladeItalienne from "../../assets/images/saladeItalienne.webp";
import ImageSaladeCesare from "../../assets/images/saladeCesare.jpg";
import ImageStromboli from "../../assets/images/stromboli.jpg";
import ImagePizzadillas from "../../assets/images/pizzadillas.jpg";

function MenuList(props) {
  const ifContainerIsPizza = props.id === "pizza";
  const ifContainerIsDessert = props.id === "dessert";
  const ifContainerIsBoisson = props.id === "boisson";
  const ifContainerIsFormule = props.id === "formule";
  const ifContainerIsEntree = props.id === "entree";
  const ifContainerIsSalade = props.id === "salade";

  //   const listePizzas = props.listMenu.contenants
  //   //.contenants[0].liste[1].listeTitre

  const listeMenu = props.listMenu.contenants[0].liste;

  // Sélectionner les trois premiers éléments du tableau listePizzas
  const firstSectionListPizzas = listeMenu.slice(0, 3);

  const secondSectionListPizzas = listeMenu.slice(3);
  return (
    <>
      {ifContainerIsPizza ? (
        <>
          <section className="mx-auto mt-5 col-10 col-lg-6">
            {firstSectionListPizzas.map((listePizza, index) => (
              <div key={index}>
                <h3 className="colorItems">
                  {listePizza.listeTitre.toUpperCase()}
                </h3>
                {listePizza.descriptifs.map((pizza, index) => (
                  <ul key={index} className="list-unstyled">
                    <li key={pizza.nom}>
                      <article className="w-100 d-flex justify-content-between flex-nowrap row">
                        <p className="nowrap title col-2 flex-shrink-0">
                          {pizza.nom}
                        </p>
                        <span className="colorItems col-7 flex-fill overflow-hidden">
                          {props.dots}
                        </span>
                        <p className="col-2 flex-shrink-0 widthPrice">
                          {pizza.prix}
                        </p>
                      </article>
                      <p className="col-10 colortext listIngredients">
                        {Array.isArray(pizza.ingredients) &&
                          pizza.ingredients.join(" / ")}
                      </p>
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </section>
          <section className="mx-auto mt-5 col-10 col-lg-6">
            {secondSectionListPizzas.map((listePizza, index) => (
              <div key={index}>
                <h3 className="colorItems">
                  {listePizza.listeTitre.toUpperCase()}
                </h3>
                {listePizza.descriptifs.map((pizza, index) => (
                  <ul key={index} className="list-unstyled">
                    <li key={pizza.nom}>
                      <article className="w-100 d-flex justify-content-between flex-nowrap row">
                        <p className="nowrap title col-2 flex-shrink-0">
                          {pizza.nom}
                        </p>
                        <span className="colorItems col-7 flex-fill overflow-hidden">
                          {props.dots}
                        </span>
                        <p className="col-2 flex-shrink-0 widthPrice">
                          {pizza.prix}
                        </p>
                      </article>
                      <p className="col-10 colortext listIngredients">
                        {Array.isArray(pizza.ingredients) &&
                          pizza.ingredients.join(" / ")}
                      </p>
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </section>
        </>
      ) : null}

      {ifContainerIsDessert || ifContainerIsBoisson ? (
        <>
          <div
            className={`w-100 my-5 d-lg-flex ${
              ifContainerIsDessert ? "flex-row-reverse" : ""
            }`}
          >
            <section className="mx-auto col-10 col-lg-6 d-flex flex-column justify-content-around">
              <ul
                className={`list-unstyled w-100 my-auto ${
                  ifContainerIsBoisson ? "mt-5" : ""
                }`}
              >
                {listeMenu.map((liste, index) => (
                  <li
                    className={`row ${ifContainerIsDessert ? "my-5" : ""}`}
                    key={index}
                  >
                    <article className="w-100 d-flex justify-content-between flex-nowrap row">
                      <p className="nowrap ms-4 title col-2 flex-shrink-0">
                        {liste.nom}
                      </p>
                      <span className="colorItems col-7 flex-fill overflow-hidden">
                        {props.dots}
                      </span>
                      <p className="col-2 flex-shrink-0 widthPrice">
                        {liste.prix}
                      </p>
                    </article>
                    {ifContainerIsBoisson && (
                      <p className="col-10 listIngredients ms-4">
                        {Array.isArray(liste.options) &&
                          liste.options.map((option, index) => (
                            <span className="colortext" key={index}>
                              {option + "  "}
                            </span>
                          ))}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            </section>
            <section className="mx-auto col-lg-4 d-flex justify-content-center align-items-center overflow-hidden ">
              {ifContainerIsDessert ? (
                <img
                  src={ImageDesserts}
                  width="460"
                  height="350"
                  alt="illustration d'un Tiramisù"
                  className="rounded-2"
                />
              ) : (
                <img
                  src={ImageBoisson}
                  width="540"
                  height="900"
                  alt="illustration d'une boisson"
                  className="rounded-2"
                />
              )}
            </section>
          </div>
          {ifContainerIsDessert ? (
            <section className="d-none my-5 w-100 d-lg-flex justify-content-around">
              <article>
                <img
                  src={ImageDesserts}
                  className="galerie rounded-3"
                  alt="illustration d'un tiramisu"
                />
              </article>
              <article>
                <img
                  src={ImageDessertsBis}
                  className="galerie rounded-3"
                  alt=" illustration de roulés au nutella"
                />
              </article>
              <article>
                <img
                  src={ImageDessertsTer}
                  className="galerie rounded-3"
                  alt="illustration d'assortiments de glaces"
                />
              </article>
            </section>
          ) : null}
        </>
      ) : null}
      {ifContainerIsFormule || ifContainerIsSalade ? (
        <>
          <section className="mx-auto mb-5 col-10 d-flex flex-column justify-content-around">
            <ul className="list-unstyled w-100 my-auto">
              {listeMenu.map((liste, index) => (
                <li className="row my-lg-5" key={index}>
                  <article className="w-100 col-6 d-flex justify-content-between flex-nowrap row">
                    <p className="nowrap title col-2 flex-shrink-0">
                      {liste.nom}
                    </p>
                    <span className="colorItems col-7 flex-fill overflow-hidden">
                      {props.dots}
                    </span>
                    <p className="col-2 flex-shrink-0 widthPrice">
                      {liste.prix}
                    </p>
                  </article>
                  <p className="col-10 colortext listIngredients">
                    {liste.description}
                  </p>
                </li>
              ))}
            </ul>
          </section>
          {ifContainerIsFormule ? (
            <section className="d-none my-5 w-100 d-lg-flex justify-content-around">
              <article>
                <img
                  src={ImagePizza}
                  className="galerie rounded-3"
                  alt="illustration d'une pizza"
                />
              </article>
              <article>
                <img
                  src={ImageDesserts}
                  className="galerie rounded-3"
                  alt="illustration d'un tiramisu"
                />
              </article>
              <article className="backGroundColor rounded-3">
                <img
                  src={ImageCannette}
                  className="galerie rounded-3"
                  alt="illustration d'un assortiment de canettes"
                />
              </article>
            </section>
          ) : null}
          {ifContainerIsSalade ? (
            <section className="d-none my-5 w-100 d-lg-flex justify-content-around">
              <article>
                <img
                  src={ImageSaladeCesare}
                  className="galerie rounded-3"
                  alt="illustration d'une salade cesare"
                />
              </article>
              <article>
                <img
                  src={ImageSaladeItalienne}
                  className="galerie rounded-3"
                  alt="illustration d'une salade italienne"
                />
              </article>
            </section>
          ) : null}
        </>
      ) : null}
      {ifContainerIsEntree ? (
        <>
          <section className="mx-auto col-10 d-flex flex-column justify-content-around">
            <ul className="list-unstyled w-100 my-auto">
              {listeMenu.map((liste, index) => (
                <li className="row" key={index}>
                  <h4>{liste.listeTitre}</h4>
                  {liste.descriptifs.map((detail, index) => (
                    <React.Fragment key={index}>
                      <article
                        className="w-100 col-6 d-flex justify-content-between flex-nowrap row"
                        key={index}
                      >
                        <p className="nowrap title col-2 flex-shrink-0">
                          {detail.nom}
                        </p>

                        <span className="colorItems col-7 flex-fill overflow-hidden">
                          {props.dots}
                        </span>

                        <p className="col-2 flex-shrink-0 widthPrice">
                          {detail.prix}
                        </p>
                      </article>

                      {detail.description ? (
                        <p className="col-10 colortext listIngredients">
                          {detail.description}
                        </p>
                      ) : null}
                    </React.Fragment>
                  ))}
                </li>
              ))}
            </ul>
          </section>
          {ifContainerIsEntree ? (
            <section className="d-none my-5 w-100 d-lg-flex justify-content-around">
              <article className="rounded-3 galerieForme overflow-hidden">
                <img
                  src={ImageStromboli}
                  className="galerie rounded-3"
                  alt="illustration de stromboli"
                />
              </article>
              <article>
                <img
                  src={ImagePizzadillas}
                  className="galerie rounded-3"
                  alt="illustration de pizza'dillas"
                />
              </article>
            </section>
          ) : null}
        </>
      ) : null}
    </>
  );
}
export default MenuList;
