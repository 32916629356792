import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  return (
    <div className="App">
        <Home/>
    </div>
  );
}

export default App;
