import React, { useEffect, useState, useRef } from 'react';
import './Header.css';
import  Logo from "../../assets/images/Logo-artisan-pizzaiolo.png";

function Header(props) {

  const [menuItems, setMenuItems] = useState([]);
  const menuRef = useRef(null);



  useEffect(() => {
    // Ajoute un gestionnaire d'événements "click" au document
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Supprime le gestionnaire d'événements lorsque le composant est démonté
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Vérifie si le clic se produit en dehors de la menuBox
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // Ferme le menu en réinitialisant le menuBox
      document.getElementById('menuBox').classList.remove('show');
    }
  };
  const handleClickInside = () => {
    document.getElementById('menuBox').classList.remove('show');
  };

  useEffect(() => {
    setMenuItems(props.data);
  }, []);
  return (
    <header className="w-100 d-flex align-items-center justify-content-center">
      <button
        id="burgerMenu"
        className="navbar-toggler d-md-none position-absolute p-2 rounded-1"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#menuBox"
        aria-controls="menuBox"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse position-absolute d-md-none" id="menuBox" ref={menuRef}>
        <ul className="list-group">
          {props.data.map((item, index) => (
              <li key={index} className="list-group-item">
              <a className="text-nowrap d-block w-100 text-center" 
              onClick={handleClickInside}
              href={item.href}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <h1 className="text-center d-none d-xl-flex">NOTRE CARTE</h1>
       <img
          className="d-flex justify-content-center d-xl-none"
          src={Logo}
          alt="Logo artisan pizzaiolo"
        />
    </header>
  );
}

export default Header;