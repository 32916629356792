import React from 'react'
import './containerMenuList.css';
import HeaderMenuList from '../headerMenuList/HeaderMenuList';
import MenuList from '../menuList/MenuList';
import FooterMenuList from '../footerMenuList/FooterMenuList';

function ContainerMenuList(props) {
    const idContainer =props.data.categorie;
    const dots =
    "........................................................................................................................................................................................................................................................................................................";

  return (
    <article id={idContainer} className='row w-100 my-5 p-3 pt-0'>
        <HeaderMenuList title={props.data.label} dots={dots} id={idContainer}/>
        <MenuList id={idContainer} dots={dots} listMenu={props.data} />
    </article>
  )
}

export default ContainerMenuList;